// TODO Silvio use shared type
export enum ProcessingStatus {
  Complete = 'complete',
  Failed = 'failed',
  Pending = 'pending',
  Attributing = 'attributing',
  Processing = 'processing',
  Skipped = 'skipped',
  Rejected = 'rejected',
  Quarantined = 'quarantined',
}

export const getProgressBarOptions = (status: ProcessingStatus) => {
  switch (status) {
    case ProcessingStatus.Complete:
      return {
        completion: 100,
      };
    case ProcessingStatus.Skipped:
    case ProcessingStatus.Failed:
      return {
        isAborted: true,
        completion: 100,
      };
    case ProcessingStatus.Pending:
      return {
        isPending: true,
        completion: 50,
      };
    case ProcessingStatus.Rejected:
      return {
        completion: 100,
        isAborted: true,
      };
    case ProcessingStatus.Quarantined:
      return {
        completion: 67,
        isPaused: true,
      };
    case ProcessingStatus.Attributing:
      return {
        completion: 85,
      };
    default:
      return {
        completion: 0,
      };
  }
};
