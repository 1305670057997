import { ascending } from 'd3';
import { DateTime } from 'luxon';
import { IAreaGraph, IRegion } from '@/types/explorer';
import { ProcessingStatus } from '@/utils/progressbar';
import { weeksForRange } from '@/utils/dates';

export const COVERAGE_ROOT = '/network/coverage';
export const HEADER_HEIGHT = 64;
const KM_APPROX = 0.02172661; // 0.02172661 = 2 * avg. edge length of L12 H3 Cell

// export const getProgressColor = (percent: number) => {
//   const color = palette.secondary.main;
//   if (percent < 15) return alpha(color, 0.1);
//   if (percent < 25) return alpha(color, 0.2);
//   if (percent < 35) return alpha(color, 0.3);
//   if (percent < 45) return alpha(color, 0.4);
//   if (percent < 55) return alpha(color, 0.5);
//   if (percent < 65) return alpha(color, 0.6);
//   if (percent < 75) return alpha(color, 0.7);
//   if (percent < 85) return alpha(color, 0.8);
//   if (percent < 95) return alpha(color, 0.9);
//   return color;
// };

// Formats the number into 24.2M or 24.2 million
export const formatNumber = (
  num: number,
  symbol: 'long' | 'short' = 'short',
  includeK = false,
  maximumFractionDigitsOverride?: number,
) => {
  const lookup = [
    {
      value: 1e9,
      symbol: {
        short: 'B',
        long: ' billion',
      },
      digits: 2,
    },
    {
      value: 1e6,
      symbol: {
        short: 'M',
        long: ' million',
      },
      digits: 2,
    },
  ];
  if (includeK) {
    lookup.push({
      value: 1e3,
      symbol: {
        short: 'K',
        long: ' thousand',
      },
      digits: 1,
    });
  }
  lookup.push({
    value: 1,
    symbol: {
      short: '',
      long: '',
    },
    digits: 0,
  });
  const item = lookup.find(item => num >= item.value);
  if (!item) return 0;
  return `${(maximumFractionDigitsOverride === 0
    ? Math.floor(num / item.value)
    : num / item.value
  ).toLocaleString('en-US', {
    minimumFractionDigits: maximumFractionDigitsOverride ?? item.digits,
    maximumFractionDigits: maximumFractionDigitsOverride ?? item.digits,
  })}${item.symbol[symbol]}`;
};

export const groupByMonth = (rawData: { [key: string]: any } | string[]) => {
  const monthSet: Set<string> = new Set();
  const months: { [key: string]: boolean } = {};
  let keys: string[];
  if (Array.isArray(rawData)) {
    keys = [...rawData];
  } else {
    keys = Object.keys(rawData).sort((a, b) =>
      ascending(new Date(a), new Date(b)),
    );
  }

  keys
    .sort((a, b) => ascending(new Date(a), new Date(b)))
    .forEach(k => {
      const [year, month] = k.split('-');
      monthSet.add(`${year}-${month}`);
    });

  if (monthSet.size > 3) {
    const [firstMonth] = monthSet;
    monthSet.delete(firstMonth);
  }

  monthSet.forEach(month => {
    months[month] = false;
  });

  return months;
};

const sortGraphRecordByDate = (a: IAreaGraph, b: IAreaGraph) =>
  ascending(a.date, b.date);

export const transformRegionDataForGraphs = (rawData: {
  [key: string]: number;
}) => {
  const data: IAreaGraph[] = [];
  const currentWeekData: IAreaGraph[] = [];
  const lastWeekData: IAreaGraph[] = [];
  let dotReferenceData: IAreaGraph = {};
  const allEntries = Object.entries(rawData || {}).sort((a: any, b: any) =>
    ascending(a[0], b[0]),
  );
  let i = 0;
  for (const [date, value] of allEntries) {
    const currentData = { date, value: value * 100 };
    const nullData = { date };
    data.push(currentData);
    if (i < allEntries.length - 2) {
      // Last week data
      lastWeekData.push(currentData);
      currentWeekData.push(nullData);
    } else {
      // Current week data
      currentWeekData.push(currentData);
      if (i === allEntries.length - 2) {
        // Reference point
        dotReferenceData = currentData;
        // Last week data uses the reference point
        lastWeekData.push(currentData);
      } else {
        lastWeekData.push(nullData);
      }
    }
    i++;
  }
  data.sort(sortGraphRecordByDate);
  lastWeekData.sort(sortGraphRecordByDate);
  currentWeekData.sort(sortGraphRecordByDate);
  const maxValue = Math.max(...data.map(d => d?.value || 0));
  return {
    data,
    lastWeekData,
    currentWeekData,
    dotReferenceData,
    maxValue,
  };
};

export const transformContributorStats = (
  rawData: {
    [key: string]: number;
  },
  formatter = ({ date, value }: IAreaGraph) => ({ date, value }),
) => {
  const data: IAreaGraph[] = [];
  const allEntries = Object.entries(rawData || {}).sort((a: any, b: any) =>
    ascending(a[0], b[0]),
  );
  for (const [date, value] of allEntries) {
    data.push(formatter({ date, value }));
  }
  data.sort(sortGraphRecordByDate);
  return data;
};

export const getProgressData = (dataSource: { [key: string]: any }) => {
  const dates = Object.keys(dataSource).sort(
    (a, b) => new Date(b).getTime() - new Date(a).getTime(),
  );
  // Get previous week data
  if (dates.length > 1) {
    return dataSource[dates[1]];
  } else return dataSource[dates[0]];
};

export const getRoundPercent = (val = 0) => {
  return Math.round(val * 100);
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case ProcessingStatus.Complete:
      return 'veridianSuccess.500';
    case ProcessingStatus.Skipped:
    case ProcessingStatus.Failed:
    case ProcessingStatus.Rejected:
      return 'error.main';
    case ProcessingStatus.Quarantined:
    case ProcessingStatus.Pending:
      return 'warning.main';
    default:
      return 'azure.600';
  }
};

export const getTotalKm = (l12Cells: number) => {
  return Math.round(l12Cells * KM_APPROX);
};

export const getMultiplierFactor = (percent = 0) => {
  if (percent >= 90) return '3x';
  if (percent >= 80) return '2x';
  if (percent >= 70) return '1.5x';
  return '1x';
};

export const normalizeText = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

export const formatDateAxis = (
  d: string,
  months: { [key: string]: boolean },
  shortMonth?: boolean,
) => {
  if (!d) return '';
  const [year, month, day] = d.split('-');
  const date = `${year}-${month}`;
  if (months[date] === false) {
    months[date] = true;
    return formatDateUS(+year, +month, +day, false, false, shortMonth);
  }
  return '';
};

export const formatPercentAxis = (d: any) => `${d}${d === 100 ? '%' : ''}`;

export const formatDateUS = (
  year: number,
  month: number,
  day: number,
  showDay?: boolean,
  showYear: boolean = true,
  shortMonth?: boolean,
) =>
  new Date(year, month - 1, day).toLocaleDateString('en-US', {
    ...(showYear && { year: 'numeric' }),
    month: shortMonth ? 'short' : 'long',
    ...(showDay && { day: 'numeric' }),
  });

export const sanitizeRegion = (region: string) => {
  return normalizeText(region.replaceAll(' ', '-'));
};

export const getDefaultByWeekData = (defaultData: any) => {
  // last 90 days of weeks
  const weeks = weeksForRange(new Date(), 90);
  const result: Record<string, any> = {};
  weeks.forEach(week => {
    result[week] = defaultData;
  });
  return result;
};

export const transformKmUploadedByDay = (rawData: {
  [key: string]: number;
}) => {
  const today = DateTime.now();
  const length = 10; // total days
  const data: IAreaGraph[] = Array.from({ length })
    .map((_, i) => {
      const date = today.minus({ days: i }).toISODate();
      return {
        date,
        value: rawData[date] || 0,
      };
    })
    .sort(sortGraphRecordByDate);
  return data;
};

export const transformKmUploadedByDayBasedOnHexes = (rawData: {
  [key: string]: number;
}) => {
  const today = DateTime.now();
  const length = 10; // total days
  const data: IAreaGraph[] = Array.from({ length })
    .map((_, i) => {
      const date = today.minus({ days: i }).toISODate();
      return {
        date,
        value: getTotalKm(rawData[date] || 0),
      };
    })
    .sort(sortGraphRecordByDate);

  const correctedData: IAreaGraph[] = [];
  correctedData.push(data[0]);

  for (let i = 1; i < data.length; i++) {
    const prev = data[i - 1]?.value || 0;
    const curr = data[i]?.value || 0;
    if (prev > curr) {
      correctedData.push({
        date: data[i].date,
        value: prev,
      });
    } else {
      correctedData.push(data[i]);
    }
  }
  return correctedData;
};

export const getMaxValue = (data: IAreaGraph[]) => {
  return Math.max(...data.map(d => d?.value || 0));
};

export const transformReferralStats = (orders: any) => {
  const weekMap = getDefaultByWeekData(0);
  const weeks = Object.keys(weekMap).sort((a, b) =>
    ascending(new Date(a), new Date(b)),
  );
  let accumlatedOrders = 0;
  for (const order of orders) {
    const orderWeek = DateTime.fromISO(order.created, {
      zone: 'utc',
    }).startOf('week');
    if (orderWeek < DateTime.fromISO(weeks[0])) {
      accumlatedOrders += 1;
    } else {
      weekMap[orderWeek.toISODate()!] += 1;
    }
  }
  return weeks.map(week => {
    const value = weekMap[week] + accumlatedOrders;
    accumlatedOrders = value;
    return {
      date: week,
      value,
    };
  });
};

export const formatProgressData = (
  data: { [key: string]: number },
  mostRecentDay: string,
) => {
  const result: IAreaGraph[] = Object.entries(data)
    .filter(([date]) => date !== mostRecentDay)
    .map(([date, value]) => ({ date, value: value * 100 }))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  return result;
};

export const calculateTopPercentMapped = (
  sortedRegions: IRegion[],
  totalKm: number,
  percent: number,
): number => {
  const topRegions = sortedRegions.slice(0, percent);
  const totalKmMappeTopRegions = topRegions.reduce(
    (sum, region) => sum + region.osmKm,
    0,
  );
  const percentMappedTop = (totalKmMappeTopRegions / totalKm) * 100;
  return percentMappedTop;
};

export const calculateCoverageArea = (regions: IRegion[]) => {
  const data = {
    usa: { totalOsmKm: 0, totalMappedKm: 0, totalCountries: 0 },
    europe: { totalOsmKm: 0, totalMappedKm: 0, totalCountries: 0 },
    eastAsia: { totalOsmKm: 0, totalMappedKm: 0, totalCountries: 0 },
    canada: { totalOsmKm: 0, totalMappedKm: 0, totalCountries: 0 },
  };

  regions.forEach(region => {
    const regionCountryCodes = region.metadata?.countries?.map(
      country => country.isoCode,
    );

    const updateData = (area: 'usa' | 'europe' | 'eastAsia' | 'canada') => {
      data[area].totalOsmKm += region.osmKm;
      data[area].totalMappedKm += getTotalKm(region.uniqueCoveredL12Cells);
      data[area].totalCountries += 1;
    };

    if (regionCountryCodes?.some(code => usa.includes(code))) {
      updateData('usa');
    }
    if (regionCountryCodes?.some(code => europeanCountries.includes(code))) {
      updateData('europe');
    }
    if (regionCountryCodes?.some(code => eastAsianCountries.includes(code))) {
      updateData('eastAsia');
    }
    if (regionCountryCodes?.some(code => canada.includes(code))) {
      updateData('canada');
    }
  });

  const calculatePercentage = (
    area: 'usa' | 'europe' | 'eastAsia' | 'canada',
  ) => {
    const { totalOsmKm, totalMappedKm } = data[area];
    if (totalOsmKm === 0) return 0;
    const total = (totalMappedKm / totalOsmKm) * 100;
    if (total >= 100) return 99;
    return total;
  };

  return {
    usa: calculatePercentage('usa'),
    europe: calculatePercentage('europe'),
    eastAsia: calculatePercentage('eastAsia'),
    canada: calculatePercentage('canada'),
  };
};

const usa = ['US'];

const canada = ['CA'];

const europeanCountries = [
  'UK', // United Kingdom
  'GB', // United Kingdom (Great Britain)
  'AL', // Albania
  'AD', // Andorra
  'AT', // Austria
  'BY', // Belarus
  'BE', // Belgium
  'BA', // Bosnia and Herzegovina
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IS', // Iceland
  'IE', // Ireland
  'IT', // Italy
  'XK', // Kosovo
  'LV', // Latvia
  'LI', // Liechtenstein
  'LT', // Lithuania
  'LU', // Luxembourg
  'MK', // North Macedonia
  'MT', // Malta
  'MD', // Moldova
  'MC', // Monaco
  'ME', // Montenegro
  'NL', // Netherlands
  'NO', // Norway
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SM', // San Marino
  'RS', // Serbia
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'TR', // Turkey
  'UA', // Ukraine
  'VA', // Vatican City
];

const eastAsianCountries = [
  'JP', // Japan
  'KR', // South Korea
  'TW', // Taiwan
];
