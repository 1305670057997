import { DateTime } from 'luxon';

export enum TimeframeEnum {
  hour = 'hour',
  day = 'day',
  week = 'week',
  all = 'all',
}

export const fullDate = (time: string) => {
  if (!time) {
    return '';
  }

  const date = new Date(time);
  const humanReadableDate = DateTime.fromJSDate(date).toFormat(
    "yyyy LLL dd' at 'hh:mm:ss:SSS' 'a",
  );

  return humanReadableDate;
};

export const yearMonthDayToLocal = (
  timestamp: number | string,
  includeTime?: boolean,
) => {
  if (!timestamp) {
    return '';
  }

  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  const time = includeTime ? `/ ${hours}:${minutes}:${seconds} ${ampm}` : '';
  const formattedDate = `${year}-${month}-${day} ${time}`;

  return formattedDate;
};

export const monthDayTime = (time: string | Date) => {
  if (!time) {
    return '';
  }

  const date = new Date(time);
  const humanReadableDate = DateTime.fromJSDate(date).toFormat(
    "LLL dd' at 'hh:mm' 'a",
  );

  return humanReadableDate;
};

export const diffInDays = (date1: Date, date2: Date): number => {
  // @ts-ignore
  return Math.round((date2 - date1) / 1000 / 86400);
};

export const normalizedDateMS = (t: Date, minTime: Date, maxTime: Date) => {
  // @ts-ignore
  return (Math.max(minTime, t.getTime()) - minTime) / (maxTime - minTime);
};

export const getDateDaysAgo = (days: number) => {
  const d = new Date();
  return d.setDate(d.getDate() - days);
};

export const prettyDate = (
  time: string | Date,
  longFormat?: boolean,
  lateTime?: string,
) => {
  if (!time) {
    return '';
  }

  const date = new Date(time);
  const lateDate = !lateTime ? new Date() : new Date(lateTime);
  const diff = (lateDate.getTime() - date.getTime()) / 1000;
  const day_diff = Math.floor(diff / 86400);

  const result =
    (day_diff <= 0 &&
      ((diff < 60 && 'Just now') ||
        (longFormat && diff < 120 && '1 minute ago') ||
        (diff < 3600 &&
          Math.floor(diff / 60) + (longFormat ? ' minutes ago' : 'm')) ||
        (longFormat && diff < 7200 && '1 hour ago') ||
        (diff < 86400 &&
          Math.floor(diff / 3600) + (longFormat ? ' hours ago' : 'h')))) ||
    (longFormat && day_diff == 1 && 'Yesterday') ||
    (day_diff < 7 && day_diff + (longFormat ? ' days ago' : 'd')) ||
    (day_diff < 31 &&
      Math.ceil(day_diff / 7) +
        (longFormat
          ? ` week${Math.ceil(day_diff / 7) > 1 ? 's' : ''} ago`
          : 'w')) ||
    (day_diff >= 31 &&
      Math.ceil(day_diff / 31) +
        (longFormat
          ? ` month${Math.ceil(day_diff / 31) > 1 ? 's' : ''} ago`
          : 'mo'));

  return result || '';
};

export const formatQueryDate = (date: Date | null) => {
  if (!date) return;
  return `${date?.getFullYear()}-${(date?.getMonth() + 1).toLocaleString(
    'en-US',
    { minimumIntegerDigits: 2 },
  )}-${date?.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2 })}`;
};

export const validateQueryDate = (dateRaw: string) => {
  if (!dateRaw) return;
  // Should be in the format yyyy-mm-dd or ISO
  const regex =
    /^\d{4}(-(\d{2})(-(\d{2})(T(\d{2}):(\d{2})(:(\d{2}))?(\.\d+)?(([+-](\d{2}):(\d{2}))|Z)?)?)?)?$/;
  if (regex.test(dateRaw)) {
    return new Date(dateRaw);
  }
};

export function weeksForRange(start: Date, numDays: number) {
  const weeks = new Set<string>();

  const o = new Date(String(start));
  let d = DateTime.fromJSDate(o, { zone: 'utc' });
  for (let i = 0; i < numDays; i++) {
    weeks.add(utcDayString(weekOfDate(d.toJSDate())));
    d = d.minus({ days: 1 });
  }

  return Array.from(weeks);
}

export function utcDayString(date: Date | string) {
  const o = new Date(String(date));
  const d = DateTime.fromJSDate(o, { zone: 'utc' });
  return d.toFormat('yyyy-MM-dd');
}

/**
 * Return a new date zeroed to the first day of the intersecting week
 */
export function weekOfDate(date: Date | string) {
  const o = new Date(String(date));
  const d = DateTime.fromJSDate(o, { zone: 'utc' });
  const w = d.toISOWeekDate()!.replace(/-\d$/, '-1');
  const n = DateTime.fromISO(w, { zone: 'utc' });
  return new Date(n.toString());
}

export function getMonthAndDay(date: Date | string) {
  const dateFormat = new Date(date);
  const formattedDate = dateFormat.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
  });

  return formattedDate;
}

export const getNumDaysFromTimeframe = (timeframe: TimeframeEnum) => {
  switch (timeframe) {
    case 'hour':
      return 1;
    case 'day':
      return 1;
    case 'week':
      return 7;
    case 'all':
      return 7;
    default:
      return 1;
  }
};

export function getLastThreeMondays() {
  let date = new Date();

  const oneDayInMillis = 24 * 60 * 60 * 1000;
  const mondays = [];

  while (mondays.length < 3) {
    if (date.getDay() === 1) {
      // Check if the day is Monday (1 represents Monday)
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      mondays.push(`${year}-${month}-${day}`);
    }
    // Move one day back
    date = new Date(date.getTime() - oneDayInMillis);
  }

  return mondays;
}

// https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site
export function timeSince(date: Date) {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' days';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}
